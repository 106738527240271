import React from 'react';
import { motion } from 'framer-motion';
import { ExternalLink, ArrowUpRight, BookOpen, Calendar, Clock } from 'lucide-react';

const ArticlesSection = () => {
  const articles = [
    {
      id: 1,
      title: "Bengaluru-based AI Startup LatentForce.AI Enables LLM-Powered Data Extraction",
      source: "Analytics India Magazine",
      date: "December 2024",
      readTime: "5 min read",
      excerpt: "Discover how LatentForce.AI is transforming data extraction with advanced LLM technology, serving enterprises across industries.",
      link: "https://www.linkedin.com/posts/analytics-india-magazine_bengaluru-based-ai-driven-startup-latentforceai-activity-7277266141595508736-z91a"
    },
    {
      id: 2,
      title: "LatentForce.AI Revolutionizes Data Extraction with LLMs",
      source: "AI Plus Info",
      date: "December 2024",
      readTime: "4 min read",
      excerpt: "An in-depth look at how LatentForce.AI's innovative approach to data extraction is setting new industry standards.",
      link: "https://www.aiplusinfo.com/blog/latentforce-ai-revolutionizes-data-extraction-with-llms/"
    },
    {
      id: 3,
      title: "LatentForce.AI Introduces Extractors.AI: Revolutionizing Data Extraction",
      source: "CXO Digital Pulse",
      date: "December 2024",
      readTime: "6 min read",
      excerpt: "Exploring the launch of Extractors.AI and its impact on automated data processing workflows.",
      link: "https://www.cxodigitalpulse.com/latentforce-ai-introduces-extractors-ai-revolutionizing-data-extraction-with-generative-ai/"
    },
    {
      id: 4,
      title: "LatentForce.AI Enables LLM-Powered Document Conversion Tasks",
      source: "Analytics India Magazine",
      date: "December 2024",
      readTime: "4 min read",
      excerpt: "Learn about the innovative document conversion capabilities powered by LatentForce.AI's advanced LLM technology.",
      link: "https://analyticsindiamag.com/ai-news-updates/bengaluru-based-latentforce-ai-enables-llm-powered-data-extraction-document-conversion-tasks/"
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <section className="py-20 px-4 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800" id="articles">
      <motion.div
        className="max-w-7xl mx-auto"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={containerVariants}
      >
        {/* Section Header */}
        <motion.div variants={cardVariants} className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
              In the News
            </span>
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            See How LatentForce.ai is making waves in the Tech industry
          </p>
        </motion.div>

        {/* Articles Grid */}
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 gap-8"
          variants={containerVariants}
        >
          {articles.map((article) => (
            <motion.a
              key={article.id}
              href={article.link}
              target="_blank"
              rel="noopener noreferrer"
              variants={cardVariants}
              whileHover={{ scale: 1.02 }}
              className="group"
            >
              <div className="h-full rounded-2xl overflow-hidden bg-white dark:bg-gray-800 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 dark:border-gray-700">
                <div className="p-8">
                  {/* Article Header */}
                  <div className="flex items-center justify-between mb-4">
                    <span className="px-4 py-1 rounded-full text-sm font-medium bg-purple-100 dark:bg-purple-900 text-purple-600 dark:text-purple-300">
                      {article.source}
                    </span>
                    <div className="flex items-center space-x-4 text-gray-400">
                      <div className="flex items-center">
                        <Calendar className="w-4 h-4 mr-2" />
                        <span className="text-sm">{article.date}</span>
                      </div>
                      <div className="flex items-center">
                        <Clock className="w-4 h-4 mr-2" />
                        <span className="text-sm">{article.readTime}</span>
                      </div>
                    </div>
                  </div>

                  {/* Article Title */}
                  <h3 className="text-xl font-bold mb-4 text-gray-800 dark:text-white group-hover:text-purple-600 dark:group-hover:text-purple-400 transition-colors duration-300">
                    {article.title}
                  </h3>

                  {/* Article Excerpt */}
                  <p className="text-gray-600 dark:text-gray-300 mb-6">
                    {article.excerpt}
                  </p>

                  {/* Article Footer */}
                  <div className="flex items-center justify-between mt-6 pt-6 border-t border-gray-100 dark:border-gray-700">
                    <div className="flex items-center text-purple-600 dark:text-purple-400">
                      <BookOpen className="w-5 h-5 mr-2" />
                      <span className="font-medium">Read article</span>
                    </div>
                    <ArrowUpRight className="w-5 h-5 text-purple-600 dark:text-purple-400 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform duration-300" />
                  </div>
                </div>
              </div>
            </motion.a>
          ))}
        </motion.div>

      </motion.div>
    </section>
  );
};

export default ArticlesSection;