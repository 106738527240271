// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;















import React from 'react';
import { Toaster } from 'react-hot-toast'; // Import Toaster
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import ProductsSection from './components/ProductsSection';
import AgentsSection from './components/AgentsSection';
import TeamSection from './components/TeamSection';
import ClientShowcase from './components/ClientShowcase';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import LatentForceChat from './components/LatentForceChat'
import ArticlesSection from './components/ArticlesSection';


function App() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-white via-violet-50 to-indigo-50 dark:from-gray-900 dark:via-violet-950 dark:to-indigo-950 transition-colors duration-300">
      {/* Add Toaster at the root level */}
      <Toaster />
      <Navbar />
      <HeroSection />
      <ProductsSection />
      <ArticlesSection/>
      <AgentsSection />
      <ClientShowcase />
      <TeamSection />
      <ContactSection />
      <Footer />
      <LatentForceChat />
    </div>
  );
}

export default App;




